import { findCategoryByProductId, sortProducts } from '..';
import { AnalyticsItem } from '../../models/googleAnalytics.model';
import store from '../../redux';
import { gaEvent } from './gaEvent';

export enum GACheckoutStep {
  VIEW_CART = 1,
  BILLING_INFO = 2,
  PAYMENT_INFO = 3,
  PAYMENT_COMPLETE = 4,
}

const getStepName = (checkoutStep: GACheckoutStep): string => {
  switch (checkoutStep) {
    case GACheckoutStep.VIEW_CART:
      return 'View Cart';
    case GACheckoutStep.BILLING_INFO:
      return 'Billing Information';
    case GACheckoutStep.PAYMENT_INFO:
      return 'Payment Information';
    case GACheckoutStep.PAYMENT_COMPLETE:
      return 'Payment Complete';
  }
};

const getEventName = (
  checkoutStep: GACheckoutStep,
): Gtag.EventNames | 'checkout_option' => {
  switch (checkoutStep) {
    case GACheckoutStep.VIEW_CART:
      return 'view_cart';
    case GACheckoutStep.BILLING_INFO:
      return 'begin_checkout';
    case GACheckoutStep.PAYMENT_INFO:
    case GACheckoutStep.PAYMENT_COMPLETE:
    default:
      return 'checkout_option';
  }
};

export const checkoutStep = (
  checkoutStep: GACheckoutStep,
  checkout_payment_type?: string,
) => {
  const items = getItemsListForGAEvent();
  const {
    order: { currency, orderTotal },
  } = store.getState();

  gaEvent(getEventName(checkoutStep), {
    checkout_step: checkoutStep,
    checkout_step_name: getStepName(checkoutStep),
    value: orderTotal || undefined,
    currency: currency || undefined,
    items: items.length > 0 ? items : undefined,
    checkout_payment_type,
  });
};

export const getItemsListForGAEvent = (): Gtag.Item[] => {
  const {
    menu: { categories },
    order: { products, currency },
    places: { activePlace },
    discountCode,
  } = store.getState();
  const coupon = discountCode.currentAppliedCode;
  const cartItems = sortProducts(products);
  const items: AnalyticsItem[] = [];

  cartItems.forEach((cartItem: any, index: number) => {
    const currentCategory = findCategoryByProductId(
      cartItem.productId,
      categories,
    );
    items.push({
      item_id: cartItem.productId,
      item_name: cartItem.displayName,
      affiliation: 'Experience F&B',
      coupon: coupon,
      currency: currency,
      index: index,
      item_brand: activePlace.id,
      item_category: currentCategory.displayName,
      item_list_id: activePlace?.activeMenu?.id,
      item_list_name: activePlace?.activeMenu?.content?.name,
      price: cartItem.price,
      quantity: cartItem.quantity,
    });
  });
  return items;
};
